import React, { useState, useRef, useMemo } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import TextField from '@material-ui/core/TextField';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

export default function MultiInputOps(props) {
  const { item, multiInputValues, onMultiInputChange, onUploadClicked, onDownloadClicked } = props;
  const {
    name,
    fileName,
    uploadUrl,
    uploadMethod,
    getDownloadUrl,
    downloadParams,
    upldToolTip,
    dnldToolTip,
  } = item;
  const inputFile = useRef(null);
  const [isInputOptionsVisible, setInputOptionsVisibility] = useState(
    downloadParams.length === 1 ? true : false
  );

  const showDownloadBtn = useMemo(() => {
    if (!multiInputValues.length) return false;
    return true;
  }, [multiInputValues]);

  function handleChange(event, item) {
    onMultiInputChange(event.target.value, item);
  }

  return (
    <div className="flex flex-1 flex-col ml-1 mt-2">
      <div className="flex flex-row flex-grow items-center justify-between">
        <div className="flex flex-1 flex-row flex-grow items-center justify-between">
          <p>{name}</p>
          {downloadParams.length > 1 ? (
            <IconButton onClick={() => setInputOptionsVisibility(!isInputOptionsVisible)}>
              {isInputOptionsVisible ? (
                <KeyboardArrowUpIcon color="primary" />
              ) : (
                <KeyboardArrowDownIcon color="primary" />
              )}
            </IconButton>
          ) : null}
        </div>
        <div className="flex flex-2 flex-row">
          {uploadUrl ? (
            <Tooltip title={upldToolTip || 'Upload'}>
              <div>
                <IconButton
                  onClick={() => {
                    inputFile.current.click();
                  }}
                >
                  <CloudUploadIcon color="primary" />
                </IconButton>
                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    onUploadClicked({ url: uploadUrl, method: uploadMethod }, e.target.files[0]);
                  }}
                />
              </div>
            </Tooltip>
          ) : null}

          {showDownloadBtn && getDownloadUrl ? (
            <Tooltip title={dnldToolTip || 'Download'}>
              <IconButton
                onClick={() => {
                  onDownloadClicked(fileName || name, getDownloadUrl);
                }}
              >
                <GetAppRoundedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <div class="w-12"></div>
          )}
        </div>
      </div>

      {isInputOptionsVisible ? (
        <div>
          {downloadParams.length
            ? downloadParams.map((item) => (
                <TextField
                  classes={{ root: 'mt-2' }}
                  key={item.id}
                  id={item.id}
                  fullWidth={true}
                  label={item.name}
                  type="text"
                  variant="outlined"
                  size="small"
                  placeholder={item.placeholder}
                  autoFocus={false}
                  margin="none"
                  value={
                    multiInputValues.find((i) => item.id === i.id)
                      ? multiInputValues.find((i) => item.id === i.id)?.value
                      : ''
                  }
                  onChange={(event) => handleChange(event, item)}
                />
              ))
            : null}
        </div>
      ) : null}
    </div>
  );
}
