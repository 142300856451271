import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import React, { useRef, useState } from 'react';

export default function SingleInputOps(props) {
  const { item, onUploadClicked, onDownloadClicked } = props;
  const {
    name,
    uploadUrl,
    uploadMethod,
    getDownloadUrl,
    placeholder,
    fileName,
    upldToolTip,
    dnldToolTip,
  } = item;
  const [enteredValue, setEnteredValue] = useState('');
  const inputFile = useRef(null);
  return (
    <div className="flex flex-row items-center flex-grow">
      <div className="flex flex-1">
        <TextField
          id="sort-session-code-input"
          fullWidth={true}
          label={name}
          type="text"
          variant="outlined"
          size="small"
          multiline={true}
          placeholder={placeholder || 'Enter sort session code'}
          autoFocus={false}
          margin="dense"
          value={enteredValue}
          onChange={(event) => setEnteredValue(event.target.value.toUpperCase())}
        />
      </div>
      <div className="flex flex-2 flex-row">
        {uploadUrl ? (
          <Tooltip title={upldToolTip || 'Upload'}>
            <div>
              <IconButton
                onClick={() => {
                  inputFile.current.click();
                }}
              >
                <CloudUploadIcon color="primary" />
              </IconButton>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(e) => {
                  onUploadClicked({ url: uploadUrl, method: uploadMethod }, e.target.files[0]);
                }}
              />
            </div>
          </Tooltip>
        ) : null}

        {getDownloadUrl ? (
          enteredValue.length >= 2 ? (
            <Tooltip title={dnldToolTip || 'Download'}>
              <IconButton
                onClick={() => {
                  onDownloadClicked(fileName || name, getDownloadUrl, enteredValue);
                }}
              >
                <GetAppRoundedIcon />
              </IconButton>
            </Tooltip>
          ) : null
        ) : (
          <div class="w-12"></div>
        )}
      </div>
    </div>
  );
}
