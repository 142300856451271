import { useWMSDownloadFile, useWMSUploadFile } from 'apiHooks/useFileOps';
import { saveAs } from 'file-saver';
import produce from 'immer';
import React, { useRef, useState } from 'react';
import { Loader } from 'rsuite';
import { useBranchId } from 'utils/customHooks';
import { encodeSessionCode } from 'utils/utils';
import MultiInputOps from './MultiInputOps';
import SingleDateRangeOps from './SingleDateRangeOps';
import SingleInputOps from './SingleInputOps';
import TextOps from './TextOps';

export default function FcReports() {
  const branchId = useBranchId();
  const fileName = useRef(null);
  const progressText = useRef('');
  const [multiInputValues, setMultiInputValues] = useState([]);

  const [uploadFile] = useWMSUploadFile({ branchId });
  const [downloadFile, { status: downldStatus }] = useWMSDownloadFile({
    branchId,
    fileName: fileName.current,
    onSuccess: (data) => {
      if (data.config.url.includes('blank_report')) {
        saveAs(data.data, fileName.current);
      } else {
        saveAs(data.data, data.headers.filename ? data.headers.filename : fileName.current);
      }
    },
    onSettled: () => {
      progressText.current = '';
      setMultiInputValues([]);
    },
  });

  function prepareAndUploadFile({ url, method }, file) {
    const data = new FormData();
    data.append('file', file);
    uploadFile({ url, method, fileData: data });
  }

  function onMultiInputChange(value, item) {
    setMultiInputValues(
      produce((draft) => {
        if (draft.length) {
          const found = draft.find((i) => item.id === i.id);
          if (found) {
            found.value = value;
          } else {
            draft.push({ id: item.id, value: value });
          }
        } else {
          draft.push({ id: item.id, value: value });
        }
      })
    );
  }

  function getParamValue(param) {
    if (!multiInputValues.length) return undefined;

    const paramItem = multiInputValues.find((item) => item.id === param);
    return paramItem
      ? paramItem.value !== undefined || paramItem.value !== ''
        ? paramItem.value
        : undefined
      : undefined;
  }

  return (
    <div className="grid md:grid-cols-3 lg:grid-cols-4 gap-3 p-5 bg-background">
      {Object.entries(options).map(([key, value]) => (
        <div className="rounded-lg bg-white p-3" key={key}>
          <p className="font-semibold my-1">{key}</p>
          {value?.map((opt) => (
            <OpsItem
              key={opt.id}
              item={opt}
              multiInputValues={multiInputValues}
              onMultiInputChange={onMultiInputChange}
              onUploadClicked={prepareAndUploadFile}
              onDownloadClicked={(name, getUrl, args) => {
                fileName.current = `${branchId}_${name}`;
                progressText.current = 'Download in progress...';

                const encodeInput = (x) => (opt.sanitizeInput ? opt.sanitizeInput(x) : x);

                //
                if (opt.inputType === InputType.MULTI) {
                  return downloadFile({
                    method: opt.method,
                    url: getUrl(),
                    params: {
                      sessionCode: getParamValue('sessionCode'),
                      huCode: getParamValue('huCode'),
                      skuCode: getParamValue('skuCode'),
                      wave: getParamValue('wave'),
                    },
                  });
                }

                //
                if (opt.inputType === InputType.TEXT || opt.inputType === InputType.SINGLE) {
                  return downloadFile({
                    method: opt.method,
                    url: getUrl(),
                    params: { sessionCode: encodeInput(args) },
                  });
                }

                //
                if (opt.inputType === InputType.DATE_RANGE) {
                  return downloadFile({ method: opt.method, url: getUrl(), params: args });
                }
              }}
            />
          ))}
        </div>
      ))}

      {downldStatus === 'loading' ? (
        <Loader size="md" center backdrop vertical content={progressText.current} />
      ) : null}
    </div>
  );
}

export const InputType = {
  TEXT: 'text',
  SINGLE: 'single',
  DATE_RANGE: 'date_range',
  MULTI: 'multi',
};
const inputTypeToComponent = {
  [InputType.TEXT]: (props) => <TextOps {...props} />,
  [InputType.SINGLE]: (props) => <SingleInputOps {...props} />,
  [InputType.DATE_RANGE]: (props) => <SingleDateRangeOps {...props} />,
  [InputType.MULTI]: (props) => <MultiInputOps {...props} />,
};

function OpsItem(props) {
  const { item } = props;
  const { inputType } = item;
  const render = inputTypeToComponent[inputType];

  if (!render || typeof render !== 'function') return null;

  return <div className="flex flex-col">{render(props)}</div>;
}

const options = {
  // 'Warehouse Structure': [
  //   {
  //     id: 'all_bins',
  //     name: 'All Bins',
  //     inputType: 'text',
  //     fileName: 'All Bins',
  //     dnldToolTip: 'Download all bins report',
  //     upldToolTip: '',
  //     getDownloadUrl: () => 'wms/bin',
  //   },
  //   {
  //     id: 'fixed_bins_skus',
  //     name: 'Fixed Bins SKUs',
  //     inputType: 'text',
  //     fileName: 'Fixed Bins SKUs',
  //     dnldToolTip: 'Download fixed Bins SKUs report',
  //     upldToolTip: '',
  //     getDownloadUrl: () => 'wms/bin/fixed_sku',
  //   },
  //   {
  //     id: 'storage_areas',
  //     name: 'Storage areas',
  //     inputType: 'text',
  //     fileName: 'Storage areas',
  //     dnldToolTip: 'Download storage areas report',
  //     upldToolTip: '',
  //     getDownloadUrl: () => 'wms/bin/storage_area',
  //   },
  //   {
  //     id: 'storage_zones',
  //     name: 'Storage zones',
  //     inputType: 'text',
  //     fileName: 'Storage zones',
  //     dnldToolTip: 'Download storage zones report',
  //     upldToolTip: '',
  //     getDownloadUrl: () => 'wms/bin/storage_zone',
  //   },
  //   {
  //     id: 'storage_bin_types',
  //     name: 'Storage bin types',
  //     inputType: 'text',
  //     fileName: 'Storage bin types',
  //     dnldToolTip: 'Download storage bin types report',
  //     upldToolTip: '',
  //     getDownloadUrl: () => 'wms/bin/storage_type',
  //   },
  // ],

  Inventory: [
    {
      id: 'ira_session_report',
      name: 'Inventory Count session',
      placeholder: 'Enter Inventory Count Session code',
      inputType: InputType.SINGLE,
      fileName: 'Inventory Count Session Report',
      dnldToolTip: 'Download Inventory Count session report',
      getDownloadUrl: () => 'wms/ira/report',
    },
    {
      id: 'hu_current_states',
      name: 'Handling unit current states',
      inputType: InputType.TEXT,
      fileName: 'HU Current States',
      dnldToolTip: 'Download HU current states report',
      getDownloadUrl: () => 'wms/handling_unit/state/xlsx',
    },
    {
      id: 'inventory_status',
      name: 'Inventory Status',
      inputType: InputType.TEXT,
      fileName: 'Inventory Status Report',
      dnldToolTip: 'Download Inventory status report',
      getDownloadUrl: () => 'wms/storage/inventory',
    },
    {
      id: 'manual_movement_session_report',
      name: 'Internal Movement Session Report',
      placeholder: 'Enter Internal Movement Session code',
      inputType: InputType.DATE_RANGE,
      fileName: 'Internal Movement Session Report',
      dnldToolTip: 'Download internal movement report',
      getDownloadUrl: () => 'wms/manual-movement/report',
      method: 'POST',
    },
    {
      id: 'hu_events_reports',
      name: 'Handling unit events',
      inputType: InputType.MULTI,
      fileName: 'HU Events Reports',
      dnldToolTip: 'Download HU events report',
      getDownloadUrl: () => 'wms/handling_unit/events/xlsx',
      downloadParams: [
        {
          id: 'huCode',
          name: 'Handling unit events report',
          placeholder: 'Enter HU code',
        },
      ],
    },
    {
      id: 'sku_events_reports',
      name: 'SKU events',
      inputType: InputType.MULTI,
      fileName: 'SKU Events Reports',
      dnldToolTip: 'Download SKU events report',
      getDownloadUrl: () => 'wms/handling_unit/events/xlsx',
      downloadParams: [
        {
          id: 'skuCode',
          name: 'SKU events report',
          placeholder: 'Enter SKU code',
        },
      ],
    },

    {
      id: 'bin_ira',
      name: 'Inventory Count',
      inputType: InputType.DATE_RANGE,
      fileName: 'Inventory Count Report',
      dnldToolTip: 'Inventory Count report',
      getDownloadUrl: () => 'wms/bin-ira/session/report',
    },
    {
      id: 'inventory_control_violation',
      name: 'Inventory Control Violation',
      inputType: InputType.DATE_RANGE,
      fileName: 'Inventory Conrol Violation',
      dnldToolTip: 'Download Inventory status report',
      staticFile: true,
      getDownloadUrl: () =>
        'https://storage.googleapis.com/misc.stackbox.xyz/panel_assets/Inventory%20Control%20Violation.xlsx',
    },
    {
      id: 'inventory_ageing',
      name: 'Inventory Ageing',
      inputType: InputType.TEXT,
      fileName: 'Inventory Ageing',
      dnldToolTip: 'Inventory Ageing report',
      staticFile: true,
      getDownloadUrl: () =>
        'https://storage.googleapis.com/misc.stackbox.xyz/panel_assets/Inventory%20Ageing.xlsx',
    },
    {
      id: 'sku_proliferation',
      name: 'SKU Proliferation',
      inputType: InputType.TEXT,
      fileName: 'SKU Proliferation',
      dnldToolTip: 'SKU Proliferation report',
      staticFile: true,
      getDownloadUrl: () =>
        'https://storage.googleapis.com/misc.stackbox.xyz/panel_assets/SKU%20Proliferation.xlsx',
    },
    {
      id: 'inventory_comparison',
      name: 'Inventory Comparison',
      inputType: InputType.TEXT,
      fileName: 'Inventory Comparison',
      dnldToolTip: 'Inventory Comparison report',
      staticFile: true,
      getDownloadUrl: () =>
        'https://storage.googleapis.com/misc.stackbox.xyz/panel_assets/Inventory%20Comparison.xlsx',
    },
  ],

  Inbound: [
    {
      id: 'handover_session_report',
      name: 'Handover (I/B serialized) Session',
      placeholder: 'Enter HS Session code',
      inputType: InputType.SINGLE,
      fileName: 'Handover Session Report',
      dnldToolTip: 'Download handover session report',
      getDownloadUrl: () => 'wms/handover/session/report',
    },
    {
      id: 'inbound_session_report',
      name: 'Inbound Session',
      placeholder: 'Enter Inbound Session code',
      inputType: InputType.SINGLE,
      fileName: 'Inbound session report',
      dnldToolTip: 'Inbound session report',
      getDownloadUrl: () => 'wms/inbound/report',
      method: 'POST',
    },
    {
      id: 'inbound_tu_report',
      name: 'Inbound TU',
      inputType: InputType.DATE_RANGE,
      fileName: 'Inbound TU report',
      dnldToolTip: 'Inbound TU report',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'upcoming_inbound_report',
      name: 'Upcoming Inbound',
      inputType: InputType.DATE_RANGE,
      fileName: 'Upcoming Inbound report',
      dnldToolTip: 'Upcoming Inbound report',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'inbound_cockpit_dashboard_report',
      name: 'Inbound Cockpit Dashboard',
      inputType: InputType.DATE_RANGE,
      fileName: 'Inbound cockpit dashboard report',
      dnldToolTip: 'Inbound cockpit dashboard report',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'putaway_report',
      name: 'Putaway ',
      inputType: InputType.DATE_RANGE,
      fileName: 'Putaway report',
      dnldToolTip: 'Putaway report',
      getDownloadUrl: () => 'wms/cincout/putaway/session/report',
    },
    {
      id: 'grn_report',
      name: 'Goods Received Note (GRN)',
      inputType: InputType.DATE_RANGE,
      fileName: 'Goods received note (GRN) report',
      dnldToolTip: 'Goods Received note report',
      getDownloadUrl: () => 'wms/inbound/report/grn',
      method: 'POST',
    },
    {
      id: 'ib_qc_report',
      name: 'INBOUND QC Report',
      inputType: InputType.DATE_RANGE,
      fileName: 'INBOUND QC Report',
      dnldToolTip: 'INBOUND QC Report',
      getDownloadUrl: () => 'wms/inbound/qc-report',
    },
  ],

  'Outbound-SBL': [
    {
      id: 'sorting_line_status_reports',
      name: 'Sorting Line Status - Dashboard',
      inputType: InputType.DATE_RANGE,
      fileName: 'Sorting line status - Dashboard report',
      dnldToolTip: 'Download Sorting line status reports',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'sbl_crate_utilization_and_rainbow_reports',
      name: 'SBL Crate Utilization & Rainbow',
      inputType: InputType.DATE_RANGE,
      fileName: 'SBL crate utilization and rainbow report',
      dnldToolTip: 'Download SBL crate utilization and rainbow reports',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'sbl_crate_utilization_reports',
      name: 'SBL Crate Utilization',
      inputType: InputType.DATE_RANGE,
      fileName: 'SBL crate utilization report',
      dnldToolTip: 'Download SBL crate utilization reports',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'sbl_execution_reports',
      name: 'SBL Execution',
      inputType: InputType.DATE_RANGE,
      fileName: 'SBL execution report',
      dnldToolTip: 'Download SBL execution reports',
      getDownloadUrl: () => 'wms/sortation/ptl_execution/report',
    },
    {
      id: 'sbl_palletization_reports',
      name: 'SBL Palletization',
      inputType: InputType.SINGLE,
      fileName: 'SBL palletization report',
      dnldToolTip: 'Download SBL palletization reports',
      getDownloadUrl: () => 'wms/sortation/ptl_palletization/report',
    },
    {
      id: 'sbl_productivity_details_reports',
      name: 'SBL Productivity Details',
      inputType: InputType.DATE_RANGE,
      fileName: 'SBL productivity details report',
      dnldToolTip: 'Download SBL productivity details reports',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'sbl_manpower_productivity_reports',
      name: 'SBL Manpower Productivity',
      inputType: InputType.SINGLE,
      fileName: 'SBL manpower productivity report',
      dnldToolTip: 'Download SBL manpower productivity reports',
      getDownloadUrl: () => 'wms/sortation/ptl_productivity/report',
    },
  ],

  WCS: [
    {
      id: 'ptl_pulse_report',
      name: 'PTL Pulse',
      inputType: InputType.DATE_RANGE,
      fileName: 'PTL Pulse report',
      dnldToolTip: 'PTL Pulse report',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'sbl_pulse_report',
      name: 'SBL Pulse',
      inputType: InputType.DATE_RANGE,
      fileName: 'SBL Pulse report',
      dnldToolTip: 'SBL Pulse report',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'primary_sorter_machine_activity_report',
      name: 'Primary Sorter Machine Work Order',
      inputType: InputType.DATE_RANGE,
      fileName: 'Primary Sorter Machine Work Order Report',
      dnldToolTip: 'Primary Sorter Machine Work Order Report',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'primary_sorter_machine_wave_mapping_report',
      name: 'Primary Sorter Machine Wave Mapping',
      inputType: InputType.MULTI,
      fileName: 'Primary Sorter Machine Wave Mapping Report',
      dnldToolTip: 'Primary Sorter Machine Wave Mapping Report',
      getDownloadUrl: () => 'wms/sortation/auto_lisort_wave_mappings',
      downloadParams: [
        {
          id: 'sessionCode',
          name: 'Sort Code',
          placeholder: 'Enter Sort code',
        },
        {
          id: 'wave',
          name: 'Wave',
          placeholder: 'Enter wave',
        },
      ],
    },
  ],

  Outbound: [
    {
      id: 'outbound_cockpit_report',
      name: 'Outbound Cockpit - Dashboard',
      inputType: InputType.DATE_RANGE,
      fileName: 'Outbound Cockpit Dashboard',
      dnldToolTip: 'Outbound Cockpit Dashboard',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'midmile_loading_report',
      name: 'Midmile Loading',
      inputType: InputType.DATE_RANGE,
      fileName: 'Midmile Loading Report',
      dnldToolTip: 'Midmile Loading Report',
      getDownloadUrl: () => 'wms/mmloading/report',
    },
    {
      id: 'outbound_session_report',
      name: 'Outbound Session',
      inputType: InputType.SINGLE,
      placeholder: 'Enter Outbound Session code',
      fileName: 'Outbound Session Report',
      dnldToolTip: 'Outbound Session Report',
      getDownloadUrl: () => 'wms/outbound/report',
    },
    {
      id: 'picklist_report',
      name: 'PickList',
      placeholder: 'Enter Outbound Session code',
      inputType: InputType.SINGLE,
      fileName: 'PickList Report',
      dnldToolTip: 'PickList Report',
      getDownloadUrl: () => 'wms/outbound/picking_report',
    },
    {
      id: 'wave_activity_report',
      name: 'Wave Work Order',
      inputType: InputType.DATE_RANGE,
      fileName: 'Wave Work Order Report',
      dnldToolTip: 'Wave Work Order Report',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'gin_report',
      name: 'Goods Issue Note (GIN)',
      inputType: InputType.DATE_RANGE,
      fileName: 'Goods Issue Note (GIN) report',
      dnldToolTip: 'Goods Issue Note report',
      getDownloadUrl: () => 'wms/outbound/report',
      method: 'POST',
    },
    {
      id: 'vst_report',
      name: 'Vehicle Sorting Report',
      inputType: InputType.DATE_RANGE,
      fileName: 'Vehicle Sorting Report',
      dnldToolTip: 'Vehicle Sorting Report',
      getDownloadUrl: () => 'wms/vehicle-sorting/report',
      method: 'GET',
    },
  ],

  'Outbound-PTL': [
    {
      id: 'ptl_exec_reports',
      name: 'PTL Execution',
      inputType: InputType.DATE_RANGE,
      fileName: 'PTL Execution',
      dnldToolTip: 'Download PTL execution reports',
      getDownloadUrl: () => 'wms/sortation/gtl_execution/report',
    },
    {
      id: 'ptl_productivity_details_reports',
      name: 'PTL Productivity Details',
      inputType: InputType.DATE_RANGE,
      fileName: 'PTL productivity details report',
      dnldToolTip: 'Download PTL productivity details reports',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
    {
      id: 'ptl_crate_utilization_and_rainbow_reports',
      name: 'PTL Crate Utilization & Rainbow',
      inputType: InputType.DATE_RANGE,
      fileName: 'PTL crate utilization and rainbow report',
      dnldToolTip: 'Download PTL crate utilization and rainbow reports',
      getDownloadUrl: () => 'wms/utils/blank_report',
    },
  ],

  'Outbound- Primary Sorting': [
    {
      id: 'primary_sorting_execution_reports',
      name: 'Primary Sorting Execution',
      inputType: InputType.DATE_RANGE,
      fileName: 'Primary sorting execution report',
      dnldToolTip: 'Download primary sorting execution reports',
      getDownloadUrl: () => 'wms/sortation/lisort_execution/report',
    },
  ],

  Replenishment: [
    {
      id: 'replen_report',
      name: 'Demand Based Replenishment',
      placeholder: 'Enter Outbound Session code',
      inputType: InputType.SINGLE,
      fileName: 'Demand Based Replenishment Report',
      dnldToolTip: 'Demand Based Replenishment Report',
      getDownloadUrl: () => 'wms/outbound/replen_report',
    },
    {
      id: 'auto_replen_report',
      name: 'Auto Replenishment',
      placeholder: 'Enter date range',
      inputType: InputType.DATE_RANGE,
      fileName: 'Auto Replenishment Report',
      dnldToolTip: 'Auto Replenishment Report',
      getDownloadUrl: () => 'wms/auto-replen/report',
    },
    {
      id: 'outbound_qa_report',
      name: 'Outbound QA Report',
      inputType: InputType.SINGLE,
      placeholder: 'Enter session code',
      fileName: 'Outbound QA Report',
      dnldToolTip: 'Download Outbound QA Report',
      getDownloadUrl: () => 'wms/outbound/qa-report',
    },
    {
      id: 'exception_assignment_report',
      name: 'Exception Assignment Report',
      inputType: InputType.DATE_RANGE,
      fileName: 'Exception Assignment Report',
      dnldToolTip: 'Download Exception Assignment Report',
      getDownloadUrl: () => 'wms/outbound/re-assignment-report',
    },
  ],

  Returns: [
    {
      id: 'sku_returns_reports',
      name: 'SKU Returns',
      inputType: InputType.DATE_RANGE,
      fileName: 'SKU returns report',
      dnldToolTip: 'Download SKU returns reports',
      getDownloadUrl: () => 'wms/returns/report',
    },
    {
      id: 'returns_session_report',
      name: 'WHR Returns',
      inputType: InputType.SINGLE,
      placeholder: 'Enter WHR Returns Session Code',
      fileName: 'WHR Returns',
      dnldToolTip: 'WHR Returns',
      sanitizeInput: encodeSessionCode,
      getDownloadUrl: () => 'wms/cl_returns/report',
    },
  ],

  // Uploads: [
  //   {
  //     id: 'add_new_crates_and_pallets',
  //     name: 'Add New Crates & Pallets',
  //     inputType: InputType.TEXT,
  //     fileName: 'Add new crates and pallets',
  //     dnldToolTip: 'Download crates & pallets sample',
  //     upldToolTip: 'Select file to upload handling units',
  //     getDownloadUrl: () => 'wms/handling_unit/xlsx/sample',
  //     uploadUrl: 'wms/handling_unit/xlsx',
  //   },
  //   {
  //     id: 'hu_bin_mapping',
  //     name: 'HU-Bin Mapping',
  //     inputType: InputType.TEXT,
  //     fileName: 'Add HU-Bin Mapping',
  //     upldToolTip: 'Select file to upload HU-Bin Mapping',
  //     uploadUrl: 'wms/handling_unit/store/xlsx',
  //   },
  // ],
  Productivity: [
    {
      id: 'productivity_reports',
      name: 'Worker Productivity Report',
      inputType: InputType.DATE_RANGE,
      fileName: 'Worker productivity report',
      dnldToolTip: 'Download worker productivity report',
      getDownloadUrl: () => 'wms/worker_productivity/report',
      method: 'POST',
      wms: true,
    },
    {
      id: 'idle_time_reports',
      name: 'Worker Idle Time Report',
      inputType: InputType.DATE_RANGE,
      fileName: 'Worker idle time report',
      dnldToolTip: 'Download worker idle time report',
      getDownloadUrl: () => 'wms/worker_idle_time/report',
      method: 'POST',
      wms: true,
    },
  ],
  'Parcel-Sorting': [
    {
      id: 'parcel_level_report',
      name: 'Parcel Level Report',
      inputType: InputType.DATE_RANGE,
      fileName: 'Parcel Level Report',
      getDownloadUrl: () => 'wms/parcel-sorting/session/parcel-level-report',
    },
    {
      id: 'txn_report',
      name: 'Transaction Report',
      inputType: InputType.DATE_RANGE,
      fileName: 'Transaction Report',
      getDownloadUrl: () => 'wms/parcel-sorting/session/txn-report',
    },
    {
      id: 'worker_report',
      name: 'Worker Report',
      inputType: InputType.DATE_RANGE,
      fileName: 'Worker Report',
      getDownloadUrl: () => 'wms/parcel-sorting/session/worker-report',
    },
  ],
  'QC/HU Info': [
    {
      id: 'huInfo_report',
      name: 'QC/HU Info Report',
      inputType: InputType.DATE_RANGE,
      fileName: 'HU Info Report',
      dnldToolTip: 'Download HU Info Report',
      getDownloadUrl: () => 'wms/handling_unit/info/logs/xlsx',
      method: 'GET',
    },
  ],
  'Return to Origin': [
    {
      id: 'rto_goods_issued_report',
      name: 'RTO Goods Issued Report',
      inputType: InputType.DATE_RANGE,
      fileName: 'RTO Goods Issued Report',
      getDownloadUrl: () => 'wms/return-to-origin/report',
      method: 'POST',
    },
    {
      id: 'rto_session_report',
      name: 'RTO Session Report',
      inputType: InputType.SINGLE,
      placeholder: 'Enter RTO Session Code',
      dnldToolTip: 'RTO Session Report',
      fileName: 'RTO Session Report',
      getDownloadUrl: () => 'wms/return-to-origin/report',
      method: 'GET',
    },
  ],
  'HU Movement': [
    {
      id: 'hu_movement_report',
      name: 'HU Movement Report',
      inputType: InputType.DATE_RANGE,
      fileName: 'HU Movement Report',
      getDownloadUrl: () => 'wms/hu-movement/report',
      method: 'POST',
    },
  ],
  'YMS Vehicle Summary': [
    {
      id: 'yms_vehicle_report',
      name: 'YMS Vehicle Summary Report',
      inputType: InputType.DATE_RANGE,
      fileName: 'YMS Vehicle Summary Report',
      getDownloadUrl: () => 'wms/yms/report',
      method: 'GET',
    },
  ],
};
