import { saveAs } from 'file-saver';
import { useMutation } from 'react-query';
import { useSnackbar } from 'utils/customHooks';
import fetch from 'utils/fetch';

export const useWMSDownloadFile = ({ branchId, onSettled = () => 0, fileName, onSuccess }) => {
  //
  const defaultOnSuccess = (data) => {
    saveAs(data.data, data.headers.filename ? data.headers.filename : fileName);
  };

  return useMutation(
    ({ url, params = {}, method = method ?? 'GET' }) =>
      fetch({
        url,
        method,
        responseType: 'blob',
        wholeResponse: true,
        headers: {
          'Content-Type': 'application/json, blob',
          'x-wh-id': branchId,
        },
        params: { branchId, nodeId: branchId, ...params },
      }),
    {
      onSuccess: onSuccess || defaultOnSuccess,
      onSettled,
    }
  );
};

export const useWMSUploadFile = ({ branchId }) => {
  const [notif] = useSnackbar();

  return useMutation(
    ({ url, method, fileData }) =>
      fetch({
        url: url,
        method: method || 'POST',
        accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        headers: {
          'Content-Type': 'application/json, blob',
          'x-wh-id': branchId,
        },
        params: { branchId, nodeId: branchId },
        data: fileData,
      }),
    {
      onSuccess: () => {
        notif('Uploaded successfully', { variant: 'success', autoHideDuration: 1000 });
      },
      onError: () => {
        notif('Failed to upload', { variant: 'error', autoHideDuration: 1000 });
      },
    }
  );
};
