import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import DateTimePicker from 'components/inputs/DateTimePicker';
import React, { useState } from 'react';

export default function SingleDateRangeOps(props) {
  const { item, onDownloadClicked } = props;
  const { name, getDownloadUrl, fileName, dnldToolTip, staticFile, wms } = item;
  const [dateRange, setDateRange] = useState([]);

  return (
    <div className="flex flex-row items-center flex-grow my-1">
      <div className="flex flex-1 ">
        <DateTimePicker size="md" placeholder={name || fileName} onChange={setDateRange} />
      </div>

      {dateRange.length == 2 ? (
        <Tooltip title={dnldToolTip || 'Download'}>
          {staticFile ? (
            <IconButton href={getDownloadUrl()}>
              <GetAppRoundedIcon />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                onDownloadClicked(
                  fileName,
                  getDownloadUrl,
                  wms === true
                    ? {
                        fromDate: dateRange[0],
                        toDate: dateRange[1],
                      }
                    : {
                        createdAtWindowStart: dateRange[0],
                        createdAtWindowEnd: dateRange[1],
                      }
                );
              }}
            >
              <GetAppRoundedIcon />
            </IconButton>
          )}
        </Tooltip>
      ) : (
        <div class="w-10"></div>
      )}
    </div>
  );
}
