import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import React, { useRef } from 'react';

export default function TextOps(props) {
  const { item, onUploadClicked, onDownloadClicked } = props;
  const {
    name,
    uploadUrl,
    uploadMethod,
    getDownloadUrl,
    getDownloadUrl2,
    fileName,
    upldToolTip,
    dnldToolTip,
    dnldToolTip2,
    staticFile,
  } = item;
  const inputFile = useRef(null);
  return (
    <div className="flex flex-row items-center flex-grow">
      <div className="flex flex-1">
        <p>{name}</p>
      </div>
      <div className="flex flex-2 flex-row">
        {uploadUrl ? (
          <Tooltip title={upldToolTip || 'Upload'}>
            <div>
              <IconButton
                onClick={() => {
                  inputFile.current.click();
                }}
              >
                <CloudUploadIcon color="primary" />
              </IconButton>
              <input
                type="file"
                id="file"
                ref={inputFile}
                style={{ display: 'none' }}
                onChange={(e) => {
                  onUploadClicked({ url: uploadUrl, method: uploadMethod }, e.target.files[0]);
                }}
              />
            </div>
          </Tooltip>
        ) : null}

        {staticFile ? (
          <Tooltip title={dnldToolTip || 'Download'}>
            <IconButton href={getDownloadUrl()}>
              <GetAppRoundedIcon />
            </IconButton>
          </Tooltip>
        ) : getDownloadUrl || getDownloadUrl2 ? (
          <>
            <Tooltip title={dnldToolTip || 'Download'}>
              <IconButton
                onClick={() => {
                  onDownloadClicked(fileName || name, getDownloadUrl);
                }}
              >
                <GetAppRoundedIcon />
              </IconButton>
            </Tooltip>
            {getDownloadUrl2 && (
              <Tooltip title={dnldToolTip2 || 'Download'}>
                <IconButton
                  onClick={() => {
                    onDownloadClicked(fileName || name, getDownloadUrl2);
                  }}
                >
                  <GetAppRoundedIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          <div class="w-12"></div>
        )}
      </div>
    </div>
  );
}
